<template>
  <b-row>
    <b-col md="7" lg="8" xl="8" id="capture">
      <b-row class="stand-name-report" id="header-report" style="display:none;">
        <div class="col-8">
          <b-col class="col-8">
            <h2>
              {{ stand.name }}
            </h2>
          </b-col>
          <b-col class="col-4 period">
            <span>{{ $t("stats.period") }}</span>
            <span> {{ date.start }} -</span>
            <span> {{ date.end }} </span>
          </b-col>
        </div>
      </b-row>
      <b-row class="match-height">
        <b-col class="col-8">
          <visitorsGraph
            :daily_visitors_dates="daily_visitors_dates"
            :daily_visitors_data="daily_visitors_data"
            :key="render_component"
          />
        </b-col>
        <b-col class="col-4">
          <donutDevices
            :device_categories="device_categories"
            :device_events="device_events"
            :key="render_component"
          />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col class="col-4">
          <statistic-card-with-area-chart
            icon="UserIcon"
            color="info"
            :statistic="visitors"
            :statistic-title="$t('stats.visits_stands')"
            :chartData="data.visitors"
            class="stats-card"
          />
        </b-col>
        <b-col class="col-4">
          <statistic-card-with-area-chart
            icon="UserCheckIcon"
            color="success"
            :statistic="interactions"
            :statistic-title="$t('stats.interactions_stands')"
            :chartData="data.interactions"
            class="stats-card"
          /> </b-col
        ><b-col class="col-4">
          <statistic-card-with-area-chart
            icon="UserPlusIcon"
            color="danger"
            :statistic="contacts"
            :statistic-title="$t('stats.contacts_stands')"
            :chartData="data.contacts"
            class="stats-card"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <pinInteractions
            :pinpoints="pinpoints_labels"
            :pinpoints_interactions="pinpoints_interactions"
            :key="render_component"
          />
        </b-col>
      </b-row>
    </b-col>
    <b-col md="5" lg="4" xl="4">
      <b-card
        :img-src="stand.logo_url"
        img-alt="Profile Cover Photo"
        img-top
        class="card-developer-meetup stand-dx stand-dx-stats"
      >
        <h3>{{ stand.name }}</h3>
        <h6 class="text-muted">
          {{ $t("pavilion_ref") }}
          <span class="stand_type">{{ stand.type.label }}</span>
        </h6>
        <hr class="mb-2" />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          class="mt-2"
          @click="downLoadReport()"
        >
          {{ $t("stats.download_report") }}
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import Ripple from "vue-ripple-directive";
import visitorsGraph from "./visitors-graph.vue";
import pinInteractions from "./pin-interactions.vue";
import donutDevices from "./donut-devices-chart.vue";
import BCardCode from "@core/components/b-card-code";
import { getStand } from "@api/stands.js";
import html2canvas from "html2canvas";

import {
  BBadge,
  BButton,
  BCard,
  BCardText,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormSelect,
  BAvatar,
  BImg,
} from "bootstrap-vue";
import analytics from "@api/analytics";

export default {
  components: {
    BCardCode,
    BModal,
    BBadge,
    BButton,
    BCard,
    BCardText,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BImg,
    BAvatar,
    visitorsGraph,
    donutDevices,
    StatisticCardWithAreaChart,
    pinInteractions,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        //Valori randomici per poter disegnare la curva a scopo grafico.
        visitors: [
          {
            name: "",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
          },
        ],
        interactions: [
          {
            name: "",
            data: [10, 41, 35, 49, 25, 69, 62, 101, 45],
          },
        ],
        contacts: [
          {
            name: "",
            data: [10, 41, 35, 51, 49, 62, 69, 91, 23],
          },
        ],
      },
      stand: {
        name: null,
        logo_url: null,
        type: { label: null },
        pinpoints: { type: [] },
      },
      render_component: 0,
      daily_visitors_data: [],
      daily_visitors_dates: [],
      device_categories: [],
      device_events: [],
      pinpoints_labels: [],
      pinpoints_interactions: [],
      pins: [
        "logo",
        "contacts",
        "social",
        "company",
        "industry",
        "program",
        "workshop",
        "webinar_on_demand",
        "company_webinar",
        "free_content_1",
        "free_content_2",
        "gift",
      ],
      visitors: 0,
      interactions: 0,
      contacts: 0,
      date: {
        start: "2021/11/30",
        end: "2021/12/03",
      },
    };
  },
  created() {
    let $this = this;
    getStand(this.$route.params.id)
      .then((data) => {
        this.stand = data;
        //HACK: per modificarlo in modo semplice
        document.getElementById(
          "breadcrumbsPageTitle"
        ).innerHTML = this.stand.name;
        for (let i = 0; i < this.stand.pinpoints.length; i++) {
          $this.pinpoints_labels.push($this.stand.pinpoints[i].label);
        }
        if ($this.stand.type.label == "BASIC") {
          $this.pinpoints_interactions = [0, 0, 0, 0, 0, 0];
        } else if ($this.stand.type.label == "MEDIUM") {
          $this.pinpoints_interactions = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        } else {
          $this.pinpoints_interactions = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        if ($this.rangeDate) {
          $this.getReportForDateRanges(
            $this.rangeDate.split("to")[0],
            $this.rangeDate.split("to")[1]
          );
        } else {
          $this.getReportSingleStand();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getReportSingleStand() {
      let $this = this;
      let list = [
        "visit_stand_" + this.stand.id,
        "send_contact_" + this.stand.id,
      ];
      analytics
        .getReportSingleStand(list)
        .then((data) => {
          this.visitors = parseInt(data[0].metricValues[0].value);
          this.contacts = parseInt(data[1].metricValues[0].value);
        })
        .catch((error) => {
          console.log(error);
        });
      $this.getDailyVisitorsSingleStand();
      $this.getReportDeviceCategoriesSingleStand();
      $this.getReportInteractionsPinpointsSingleStand();
      $this.forceUpdate();
    },
    getDailyVisitorsSingleStand() {
      let $this = this;
      analytics
        .getDailyVisitorsSingleStand($this.$route.params.id)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            this.formatDateforGraphs(data[i].dimensionValues[0].value);
            this.daily_visitors_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportDeviceCategoriesSingleStand() {
      let $this = this;
      analytics
        .getReportDeviceCategoriesSingleStand($this.$route.params.id)
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            $this.device_categories.push(data[i].dimensionValues[0].value);
            $this.device_events.push(parseInt(data[i].metricValues[0].value));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportInteractionsPinpointsSingleStand() {
      let $this = this;
      let list = [];
      for (let i = 0; i < this.pins.length; i++) {
        list.push("open_pinpoint_" + this.pins[i] + "_" + this.stand.id);
      }
      analytics
        .getReportInteractionsPinpointsSingleStand(list)
        .then((data) => {
          let pin_type;
          for (let x = 0; x < data.length; x++) {
            if (data[x].dimensionValues[0].value.includes("company_webinar_")) {
              pin_type =
                data[x].dimensionValues[0].value.split("_")[2] +
                " " +
                data[x].dimensionValues[0].value.split("_")[3];
            } else {
              pin_type = data[x].dimensionValues[0].value.split("_")[2];
            }
            let index = $this.pins.findIndex((element) => element == pin_type);
            $this.pinpoints_interactions[index] = parseInt(
              data[x].metricValues[0].value
            );
          }
          $this.interactions = $this.pinpoints_interactions.reduce(
            (a, b) => a + b,
            0
          );
          $this.forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportForDateRanges(start_date, end_date) {
      let $this = this;
      $this.date.start = start_date;
      $this.date.end = end_date;
      $this.resetAllData();
      let list = [
        "visit_stand_" + this.stand.id,
        "send_contact_" + this.stand.id,
      ];
      analytics
        .getReportSingleStandForDateRanges(
          list,
          start_date.replace(/\s/g, ""),
          end_date.replace(/\s/g, "")
        )
        .then((data) => {
          $this.visitors = parseInt(data[0].metricValues[0].value);
          $this.contacts = parseInt(data[1].metricValues[0].value);
        })
        .catch((error) => {
          console.log(error);
        });
      $this.getDailyVisitorsReportForDateRangesSingleStand(
        start_date.replace(/\s/g, ""),
        end_date.replace(/\s/g, "")
      );
      $this.getReportDeviceCategoriesForDateRangesSingleStand(
        start_date.replace(/\s/g, ""),
        end_date.replace(/\s/g, "")
      );
      $this.getReportInteractionsPinpointsForDateRangesSingleStand(
        start_date.replace(/\s/g, ""),
        end_date.replace(/\s/g, "")
      );
      $this.forceUpdate();
    },
    getDailyVisitorsReportForDateRangesSingleStand(start_date, end_date) {
      let $this = this;
      analytics
        .getDailyVisitorsReportForDateRangesSingleStand(
          $this.$route.params.id,
          start_date,
          end_date
        )
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            $this.formatDateforGraphs(data[i].dimensionValues[0].value);
            $this.daily_visitors_data.push(data[i].metricValues[0].value);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportDeviceCategoriesForDateRangesSingleStand(start_date, end_date) {
      let $this = this;
      analytics
        .getReportDeviceCategoriesForDateRangesSingleStand(
          $this.$route.params.id,
          start_date,
          end_date
        )
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            $this.device_categories.push(data[i].dimensionValues[0].value);
            $this.device_events.push(parseInt(data[i].metricValues[0].value));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getReportInteractionsPinpointsForDateRangesSingleStand(
      start_date,
      end_date
    ) {
      let $this = this;
      let list = [];
      for (let i = 0; i < this.pins.length; i++) {
        list.push("open_pinpoint_" + this.pins[i] + "_" + this.stand.id);
      }
      analytics
        .getReportInteractionsPinpointsForDateRangesSingleStand(
          start_date,
          end_date,
          list
        )
        .then((data) => {
          let pin_type;
          for (let x = 0; x < data.length; x++) {
            if (data[x].dimensionValues[0].value.includes("company_webinar_")) {
              pin_type =
                data[x].dimensionValues[0].value.split("_")[2] +
                " " +
                data[x].dimensionValues[0].value.split("_")[3];
            } else {
              pin_type = data[x].dimensionValues[0].value.split("_")[2];
            }
            let index = $this.pins.findIndex((element) => element == pin_type);
            $this.pinpoints_interactions[index] = parseInt(
              data[x].metricValues[0].value
            );
          }
          $this.interactions = $this.pinpoints_interactions.reduce(
            (a, b) => a + b,
            0
          );
          $this.forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetAllData() {
      this.visitors = 0;
      this.contacts = 0;
      this.daily_visitors_dates = [];
      this.daily_visitors_data = [];
      this.device_categories = [];
      this.device_events = [];
      this.pinpoints_interactions = [];
    },
    formatDateforGraphs(date) {
      let date_splitted = date.split("");
      let month = date_splitted[4] + date_splitted[5];
      let day = date_splitted[6] + date_splitted[7];
      this.daily_visitors_dates.push(day + "/" + month);
    },
    forceUpdate() {
      this.render_component += 1;
    },
    downLoadReport() {
      document.getElementById("header-report").style.display = "";
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        var anchor = document.createElement("a");
        anchor.href = canvas.toDataURL("image/jpg");
        anchor.target = "_blank";
        anchor.download = "Report" + "_" + this.stand.name + ".jpg";
        anchor.click();
        document.getElementById("header-report").style.display = "none";
      });
    },
  },
  computed: {
    rangeDate: function() {
      return this.$store.state.statistics.rangeDate;
    },
  },
  watch: {
    rangeDate: function() {
      if (this.rangeDate) {
        this.getReportForDateRanges(
          this.rangeDate.split("to")[0],
          this.rangeDate.split("to")[1]
        );
      }
    },
  },
};
</script>

<style>
.period {
  padding-bottom: 14px;
}
.stand-dx-stats {
  top: 160px;
}
</style>
